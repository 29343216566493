import axios from "axios";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { axiosInstance, titleCase } from "../util/util";
import PluginView from "../components/plugins/PluginView";
import { PageContext } from "../page-context";

const MODRINTH_API = "https://lode.gg/api/modrinth";
const LOADERS = ["SPIGOT", "PURPUR", "PAPER", "BUKKIT"];
const CATEGORIES = ["ADVENTURE", "CURSED", "DECORATION", "ECONOMY", "EQUIPMENT", "GAME_MECHANICS", "LIBRARY", "MAGIC", "MANAGEMENT", "MINIGAME", "MOBS", "OPTIMIZATION", "SOCIAL", "STORAGE", "TECHNOLOGY", "TRANSPORTATION", "UTILTY", "WORLD_GENERATION"];

export default function Plugins() {
  const [search, setSearch] = useState("");
  const [plugins, setPlugins] = useState([]);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [loaderFilter, setLoaderFilter] = useState([]);
  const [pluginStatistics, setPluginStatistics] = useState([]);

  const { screenSize } = useState(PageContext);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/landing/plugins").then((res) => setPlugins(res?.data ?? [])).catch(() => null);
  }, []);

  useEffect(() => {
    const fetchPluginData = async () => {
      const pluginDataPromises = plugins.map(async (plugin) => {
        try {
          if (plugin.paid) return plugin;

          const pluginRes = await axios.get(`${MODRINTH_API}/${plugin.id}`);
          const { description, downloads, icon_url, title, wiki_url, categories, loaders } =
            pluginRes.data;

          const versionRes = await axios.get(
            `${MODRINTH_API}/${plugin.id}/version`
          );
          const versions = versionRes.data[0]?.game_versions || [];
          const version = versionRes.data[0]?.version_number || "Unknown";

          return {
            categories,
            loaders,
            versions,
            description,
            downloads,
            icon_url,
            title,
            version,
            id: plugin.id,
            wiki_url,
          };
        } catch (error) {
          console.error(`Error fetching data for plugin ${plugin.id}:`, error);
          return null;
        }
      });

      const pluginData = await Promise.all(pluginDataPromises);
      setPluginStatistics((prev) => [...prev, ...pluginData.filter(Boolean)]);
    };

    if (plugins.length > 0) fetchPluginData();
  }, [plugins]);

  return (
    <div className="flex flex-row items-center justify-center">
      <div className="absolute background-circle min-h-screen top-[-200px] left-12 z-[-1]" />
      <div className="absolute background-circle min-h-screen bottom-[-200px] right-12 z-[-1]" />
      {/* Sidebar */}
      {/* <div className="bg-[#292929] rounded-xl px-5 py-5 w-[17rem]">
        <div className="text-gray-300 flex flex-row items-center justify-center w-max bg-[#3c3c3c]">
          <BiSearch size={25} />
          <input 
            className="bg-transparent focus:outline-none w-max"
            placeholder="Search"
          ></input>
        </div>
      </div> */}
      {/* Content */}
      <div className="ml-5 flex flex-col items-center justify-center mt-10">
        {/* <h1 className="inter-black text-4xl md:text-6xl text-center">
          Plugins
        </h1>
        <p className="text-md md:text-xl max-w-md md:max-w-3xl text-center text-gray-300 mb-3">
          View our catalogue of plugins that can help you out with your events or videos!
        </p> */}
        {/* Grid */}
        {/* Sidebar */}
        <div className="lg:items-start items-center justify-center flex-col lg:flex-row flex lg:mx-32">
          <div className="flex flex-col w-full lg:w-auto mb-5 lg:mb-0 mr-4">
            <div className="bg-tertiary-color border border-quaternary-color rounded-xl p-5">
              <h1 className="text-2xl">Plugins</h1>
              <p className="text-gray-300">View our catalogue of plugins that can help you out with your events or videos!</p>
            </div>
            {/* Search Box */}
            <div className="flex flex-row w-full items-center justify-center mt-5">
              <div className="text-gray-300 flex flex-row items-center justify-start w-full bg-tertiary-color border border-quaternary-color px-5 py-2 rounded-xl">
                <BiSearch size={25} className="cursor-pointer" onClick={() => setSearch("")} />
                <input
                  className="bg-transparent lg:ml-3 focus:outline-none w-full"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search"
                  value={search}
                />
              </div>
            </div>
            {/* Filter Categories */}
            <div className="mt-5 text-gray-300 flex flex-col items-start justify-start w-full bg-tertiary-color border border-quaternary-color px-5 py-5 rounded-xl">
              <p className="text-2xl">Filter by Loader</p>
              {
                LOADERS.map((loader) => {
                  return (
                    <div
                      key={loader}
                      className="flex flex-row items-center justify-start mt-2"
                    >
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(e) => {
                          if (e.target.checked) setLoaderFilter((prev) => [...prev, loader]);
                          else setLoaderFilter((prev) => prev.filter((c) => c !== loader));
                        }}
                      />
                      <p>{titleCase(loader)}</p>
                    </div>
                  );
                })
              }
            </div>
            {/* Filter Categories */}
            <div className="mt-5 text-gray-300 flex flex-col items-start justify-start w-full bg-tertiary-color border border-quaternary-color px-5 py-5 rounded-xl">
              <p className="text-2xl">Filter by Category</p>
              {
                CATEGORIES.map((category) => {
                  return (
                    <div
                      key={category}
                      className="flex flex-row items-center justify-start mt-2"
                    >
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(e) => {
                          if (e.target.checked) setCategoryFilter((prev) => [...prev, category]);
                          else setCategoryFilter((prev) => prev.filter((c) => c !== category));
                        }}
                      />
                      <p>{titleCase(category)}</p>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <PluginView
            categoryFilter={categoryFilter}
            plugins={plugins}
            loaderFilter={loaderFilter}
            pluginStatistics={pluginStatistics}
            screenSize={screenSize}
            search={search}
          />
        </div>
      </div>
    </div>
  );
}
