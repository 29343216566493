import Logo from "../assets/logo.svg";
import { axiosInstance, formatNumber } from "../util/util";
import { useEffect, useState } from "react";
import CodeEditor from '../assets/code.png';

import Splash from "../assets/splash.png";

export default function Landing() {

  const [creators, setCreators] = useState([]);
  const [featuredVideos, setFeaturedVideos] = useState([]);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/landing/creators").then((res) => setCreators(res?.data ?? [])).catch(() => setCreators([]));
    axiosInstance.get("https://lode.gg/api/landing/featured-videos").then((res) => setFeaturedVideos(res?.data ?? [])).catch(() => setFeaturedVideos([]));
  }, []);

  return <div className="overflow-y-hidden flex flex-col w-full items-center justify-center">
    <div className="absolute background-circle min-h-screen top-[-200px] left-12 z-[-1]" />
    <div className="absolute background-circle min-h-screen bottom-[-200px] right-12 z-[-1]" />
    <img src={Splash} alt="Splash" className="hidden md:block undraggable absolute z-[-2] md:top-40 lg:top-10 min-w-full opacity-20" />
    <div className="w-full lg:w-3/4 px-7 flex flex-col lg:flex-row items-center justify-between mt-16 lg:mt-32">
      <img src={Logo} alt="Logo" className="block lg:hidden w-1/3 animate-bounce undraggable" />
      <div className="text-center lg:text-left">
        <h1 className="text-secondary-color text-5xl lg:text-6xl max-w-3xl font-medium">Bringing the event community together.</h1>
        <h3 className="mt-5 text-gray-400 text-md lg:text-lg max-w-2xl font-semibold">Lodestone is a platform designed to bring the Minecraft event community together. From helping creators with hosting their own events, to helping players find events to play on.</h3>
        <div className="mt-7 lg:space-x-3 space-y-2 lg:space-y-0 flex flex-col items-center justify-center lg:justify-start lg:flex-row lg:items-start">
          <a href="/dashboard/events" className="rounded-lg hover:scale-105 transition-all hover:text-gray-800 hover:bg-gray-200 bg-secondary-color text-black px-6 py-2.5 font-bold">
            Join an Event
          </a>
          <a href="/dashboard/events" className="rounded-lg hover:scale-105 transition-all hover:text-gray-800 hover:bg-yellow-300 bg-yellow-400 text-black px-6 py-2.5 font-bold">
            Host an Event
          </a>
          <a href="/plugins" className="rounded-lg hover:scale-105 transition-all hover:text-gray-800 hover:bg-orange-300 bg-orange-400 text-black px-6 py-2.5 font-bold">
            Browse our Plugins
          </a>
        </div>
      </div>
      <img src={Logo} alt="Logo" className="hidden lg:block w-1/3 mt-10 animate-bounce undraggable" />
    </div>
    <div className="text-center flex flex-col items-center justify-center mt-64">
      <h1 className="font-bold text-3xl">Previously used by these various creators.</h1>
      <h3 className="font-semibold text-gray-400 text-lg">From uprising to established creators in the Minecraft Community.</h3>
      <div>
        <div>
          {
            creators && creators.length > 0 && (
              <div className="grid grid-flow-row grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 sm:gap-x-12 md:gap-x-24 gap-y-5 mx-10 mt-5">
                {creators.sort((a, b) => a.amount - b.amount).reverse().slice(0, 20).map((creator) => (
                  <a href={creator.link} key={creator.id} className="hover:scale-105 transition-transform flex flex-col items-center justify-center mt-5">
                    <img src={creator.icon_url} alt="Avatar" className="rounded-full w-10 h-10" />
                    <div className="mt-2 text-center">
                      <h1 className="text-2xl">{creator.name}</h1>
                      <p className="text-gray-400">{formatNumber(creator.amount)} {creator.type === "DISCORD" ? "Members" : "Subscribers"}</p>
                    </div>
                  </a>
                ))}
              </div>
            )
          }
        </div>
      </div>
    </div>
    <div className="w-3/4 mt-64">
      <div className="w-full">
        <div className="flex flex-row items-center">
          <div className="bg-green-400 rounded-full w-7 h-3 mr-3"></div>
          <span>Event Production & Management</span>
        </div>
        <h1 className="text-5xl mt-5 font-medium">We bring your imagination to life.</h1>
        <div className="mt-8 text-xl">
          <p>Give us an idea and we will work our magic.</p>
          <p className="text-gray-400">We will design, develop, manage, and host your events. All-in-one.</p>
        </div>
      </div>
      <div className="mb-24 mt-8">
        <div className="grid md:grid-cols-3 grid-flow-row gap-10">
          {featuredVideos && featuredVideos.map((video, index) => {
            return (
              <a
                href={video.url}
                key={`${video.author}_${index}`}
                className="flex flex-col bg-quaternary-color rounded-md overflow-hidden hover:scale-105 transition-transform"
              >
                <img
                  className="rounded-t-md object-cover w-full h-68"
                  src={video.thumbnail}
                  alt="Video thumbnail"
                />
                <div className="flex flex-row items-start justify-start p-4 w-full">
                  <img
                    src={video.author_icon}
                    className="rounded-full w-10 h-10"
                    alt="Author icon"
                  />
                  <div className="ml-3 max-w-[23rem] flex-grow">
                    <h1 className="text-md font-semibold truncate">{video.title}</h1>
                    <div className="flex flex-row items-center justify-between">
                      <div className="mt-1">
                        <h3 className="text-sm font-medium">{video.author}</h3>
                        <p className="text-[#939392] text-sm">{video.views} Views</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
    <img src={CodeEditor} alt="CodeEditor" className="hidden md:block undraggable absolute z-[-2] md:top-[220vh] lg:top-[185vh] right-[-20px] min-w-full opacity-20" />
  </div>
}
