import { BsDiscord } from "react-icons/bs";
import Banner from "../assets/banner.svg";
import { PageContext } from "../page-context";
import { useContext, useEffect, useState } from "react";
import { axiosInstance, LODE_API } from "../util/util";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaPlus, FaRegBell, FaRegCompass, FaRegUser } from "react-icons/fa";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { RiShoppingCart2Line } from "react-icons/ri";

const NavbarComponent = ({ className }) => {

  const { screenSize } = useContext(PageContext);

  const NavSection = () => {
    return <>
      <a target="_blank" href="/discord" className="hover:text-gray-100 transition-colors">Discord</a>
      <a href="/store" className="hover:text-gray-100 transition-colors">Store</a>
    </>
  }

  const SecondNavBar = ({ className }) => {
    return <div className={className}>
      <NavSection />
    </div>
  }

  return <div className={className}>
    <div className="space-x-10 text-gray-300">
      <a href="/" className="hover:text-gray-100 transition-colors">Home</a>
      <a href="/events" className="hover:text-gray-100 transition-colors">Events</a>
      <a href="/plugins" className="hover:text-gray-100 transition-colors">Plugins</a>
      {screenSize >= 768 && <NavSection />}
    </div>
    <SecondNavBar className="flex md:hidden space-x-10 flex-row items-center justify-center" />
  </div>
}

const DropdownComponent = ({ isNavOpen }) => {
  return <div className={`z-10 absolute w-48 h-68 top-24 space-y-2 border-quaternary-color border rounded-xl bg-primary-color p-5 ${isNavOpen ? "block" : "hidden"}`}>
    <a href="/dashboard/profile" className="flex flex-row items-center justify-start transition-colors hover:text-[#ffffffcd]">
      <FaRegUser className="mr-2" />
      Profile
    </a>
    <a href="/dashboard/notifications" className="flex flex-row items-center justify-start transition-colors hover:text-[#ffffffcd]">
      <FaRegBell className="mr-2" />
      Notifications
    </a>
    <a href="/dashboard/plugins" className="flex flex-row items-center justify-start transition-colors hover:text-[#ffffffcd]">
      <FiSettings className="mr-2" />
      Dashboard
    </a>
    <hr />
    <a href="/store" className="flex flex-row items-center justify-start transition-colors hover:text-[#ffffffcd]">
      <RiShoppingCart2Line className="mr-2" />
      Store
    </a>
    <a href="/events" className="flex flex-row items-center justify-start text-orange-400 transition-colors hover:text-[#fb923ccd]">
      <FaRegCompass className="mr-2" />
      Apply for Events
    </a>
    <hr />
    <a href="/dashboard/events" className="flex flex-row items-center justify-start text-green-400 transition-colors hover:text-[#4ade80cd]">
      <FaPlus className="mr-2" />
      Host an Event
    </a>
    <a href="/logout" className="flex flex-row items-center justify-start text-red-400 transition-colors hover:text-[#f87171cd]">
      <FiLogOut className="mr-2" />
      Log Out
    </a>
  </div>
}

export default function Navbar() {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const controller = new AbortController();
    axiosInstance.get(LODE_API + "/landing/user", { signal: controller.signal })
      .then((res) => setUserData(res.data))
      .catch(() => null);
    return () => controller.abort();
  }, []);

  const [isNavOpen, setNavOpen] = useState(false);

  return (
    <div className="relative flex flex-row items-center justify-center w-full">
      <div className="border border-quaternary-color py-3 px-5 w-full lg:w-3/4 flex flex-row items-center justify-between my-5 rounded-xl">
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-full flex flex-row items-center justify-between">
            <a href="/">
              <img src={Banner} alt="lodestone banner" width={140}></img>
            </a>
            <NavbarComponent className={"hidden lg:block"} />
            <div className="space-x-3">
              {
                userData ? <button onClick={() => setNavOpen(p => !p)} className="flex flex-row items-center justify-center">
                  <img src={userData.icon_url} className="w-8 rounded-full" />
                  <span className="text-gray-300 ml-2">{userData.id}</span>
                  <MdKeyboardArrowDown className="ml-1" />
                  <DropdownComponent isNavOpen={isNavOpen} />
                </button> : <a href="/login" className="flex flex-row items-center justify-center transition-colors opacity-90 hover:opacity-100 bg-quaternary-color py-2 px-4 rounded-xl">
                  <BsDiscord className="text-discord-color mr-2" />
                  Log in
                </a>
              }
            </div>
          </div>
          <NavbarComponent className={"block lg:hidden mt-3"} />
        </div>
      </div>
    </div>
  );
}
