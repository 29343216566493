import { SiSpigotmc } from "react-icons/si";
import { FaRegPaperPlane, FaStar } from "react-icons/fa";
import { LuScroll } from "react-icons/lu";
import { GrBucket } from "react-icons/gr";
import Loading from '../../assets/loading.gif';
import { TbCube } from "react-icons/tb";
import {
    FaSkullCrossbones, // CURSED
    FaPaintBrush, // DECORATION
    FaCoins, // ECONOMY
    FaTools, // EQUIPMENT
    FaDrumstickBite, // FOOD
    FaGamepad, // GAME_MECHANICS
    FaBook, // LIBRARY
    FaMagic, // MAGIC
    FaClipboardList, // MANAGEMENT
    FaDice, // MINIGAME
    FaSpider, // MOBS
    FaTachometerAlt, // OPTIMIZATION
    FaUsers, // SOCIAL
    FaBoxes, // STORAGE
    FaLaptopCode, // TECHNOLOGY
    FaTruck, // TRANSPORTATION
    FaWrench, // UTILITY
    FaGlobe, // WORLD_GENERATION
} from "react-icons/fa";
import { titleCase } from "../../util/util";

const LOADER_CLASS_NAME = "items-center justify-center flex flex-row mr-4 rounded-2xl text-[12px]";
const MODRINTH_URL = "https://modrinth.com/plugin";

function filterSerach(str, search) {
    return search.length === 0 ||
        str.id.toLowerCase().includes(search.toLowerCase()) ||
        str.title.toLowerCase().includes(search.toLowerCase()) ||
        (str.categories ?? []).find(c => c.toLowerCase().includes(search.toLowerCase())) ||
        (str.loaders ?? []).find(l => l.toLowerCase().includes(search.toLowerCase()));
}

function getCategory(category) {
    switch (category.toUpperCase()) {
        case "ADVENTURE":
            return <div className={`${LOADER_CLASS_NAME} text-[#61e730]`}>
                <FaStar size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "CURSED":
            return <div className={`${LOADER_CLASS_NAME} text-[#ff6363]`}>
                <FaSkullCrossbones size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "DECORATION":
            return <div className={`${LOADER_CLASS_NAME} text-[#6081e2]`}>
                <FaPaintBrush size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "ECONOMY":
            return <div className={`${LOADER_CLASS_NAME} text-[#22952e]`}>
                <FaCoins size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "EQUIPMENT":
            return <div className={`${LOADER_CLASS_NAME} text-[#fac042]`}>
                <FaTools size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "FOOD":
            return <div className={`${LOADER_CLASS_NAME} text-[#e6ee4c]`}>
                <FaDrumstickBite size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "GAME_MECHANICS":
            return <div className={`${LOADER_CLASS_NAME} text-[#53ea5a]`}>
                <FaGamepad size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "LIBRARY":
            return <div className={`${LOADER_CLASS_NAME} text-[#f8f377]`}>
                <FaBook size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "MAGIC":
            return <div className={`${LOADER_CLASS_NAME} text-[#df47f4]`}>
                <FaMagic size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "MANAGEMENT":
            return <div className={`${LOADER_CLASS_NAME} text-[#4485da]`}>
                <FaClipboardList size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "MINIGAME":
            return <div className={`${LOADER_CLASS_NAME} text-[#f67534]`}>
                <FaDice size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "MOBS":
            return <div className={`${LOADER_CLASS_NAME} text-[#ff2323]`}>
                <FaSpider size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "OPTIMIZATION":
            return <div className={`${LOADER_CLASS_NAME} text-[#c0eb5a]`}>
                <FaTachometerAlt size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "SOCIAL":
            return <div className={`${LOADER_CLASS_NAME} text-[#698aff]`}>
                <FaUsers size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "STORAGE":
            return <div className={`${LOADER_CLASS_NAME} text-[#f0c453]`}>
                <FaBoxes size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "TECHNOLOGY":
            return <div className={`${LOADER_CLASS_NAME} text-[#667aff]`}>
                <FaLaptopCode size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "TRANSPORTATION":
            return <div className={`${LOADER_CLASS_NAME} text-[#3eff88]`}>
                <FaTruck size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "UTILITY":
            return <div className={`${LOADER_CLASS_NAME} text-[#faf4f4]`}>
                <FaWrench size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        case "WORLD_GENERATION":
            return <div className={`${LOADER_CLASS_NAME} text-[#2ae87c]`}>
                <FaGlobe size={20} className="mr-2" />
                <p>{titleCase(category)}</p>
            </div>;
        default:
            return null; // Handle undefined category
    }
}

function getLoader(loader) {
    switch (loader.toUpperCase()) {
        case "PAPER":
            return <div className={`${LOADER_CLASS_NAME} text-[#eaa]`}>
                <FaRegPaperPlane size={15} className="mr-2" />
                <p>{titleCase(loader)}</p>
            </div>
        case "BUKKIT":
            return <div className={`${LOADER_CLASS_NAME} text-[#f6af7b]`}>
                <GrBucket size={15} className="mr-2" />
                <p>{titleCase(loader)}</p>
            </div>
        case "FABRIC":
            return <div className={`${LOADER_CLASS_NAME} text-[#dbb69b]`}>
                <LuScroll size={15} className="mr-2" />
                <p>{titleCase(loader)}</p>
            </div>
        case "SPIGOT":
            return <div className={`${LOADER_CLASS_NAME} text-[#f1cc84]`}>
                <SiSpigotmc size={15} className="mr-2" />
                <p>{titleCase(loader)}</p>
            </div>
        case "PURPUR":
            return <div className={`${LOADER_CLASS_NAME} text-[#c3abf7]`}>
                <TbCube size={15} className="mr-2" />
                <p>{titleCase(loader)}</p>
            </div>
    }
}

export default function PluginView({
    screenSize,
    plugins,
    categoryFilter,
    loaderFilter,
    pluginStatistics,
    search
}) {
    {/* List */ }
    return <div className="w-[24rem] md:w-[52rem] lg:w-[80rem]">
        {pluginStatistics.length === 0 && <div className="flex flex-row items-center justify-center ">
            <img className="mt-5" src={Loading} width={50} />
        </div>}
        {pluginStatistics
            .filter((p) => filterSerach(p, search) && (categoryFilter.length === 0 || p.categories.some(c => categoryFilter.find(x => x.toLowerCase().includes(c.toLowerCase())))) && (loaderFilter.length === 0 || p.loaders.some(l => loaderFilter.find(f => f.toLowerCase().includes(l.toLowerCase())))))
            .map((plugin) => {
                return (
                    <div
                        key={plugin.id}
                        className="rounded-xl bg-tertiary-color border border-quaternary-color px-5 py-5 mx-1 my-1"
                    >
                        <div className="mx-4 flex flex-row items-start justify-between">
                            <div className="flex flex-row items-center justify-center">
                                <img
                                    alt="logo"
                                    src={plugin.icon_url}
                                    width={100}
                                    className="rounded-xl w-[8rem] mr-5"
                                />
                                <div>
                                    <div className="flex flex-row items-end justify-start">
                                        <h1 className="text-2xl mr-2">{plugin.title}</h1>
                                        <p className="text-gray-300 text-md">{plugin.version}</p>
                                    </div>
                                    <p className="text-gray-300 max-w-xl">{plugin.description}</p>
                                    <div className="flex flex-row items-center justify-start mt-3">
                                        <div className="items-center justify-start text-gray-300 flex flex-row">
                                            {(plugin.loaders ?? []).map(loader => getLoader(loader))}
                                        </div>
                                        <span className="text-gray-600 mr-3">|</span>
                                        <div className="items-center justify-start text-gray-300 flex flex-row">
                                            {(plugin.categories ?? []).map(category => getCategory(category))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <a
                                    rel="noreferrer"
                                    href={!plugin.paid ? `${MODRINTH_URL}/${plugin.id}` : `https://lode.gg/shop/${plugin.id}/checkout`}
                                    className={`font-extrabold text-black ${plugin.paid ? "bg-red-400" : "bg-green-400"} w-full px-3 py-2 rounded-xl flex items-center justify-center mt-4`}
                                >
                                    {plugin.paid ? `Purchase for $${plugin.price}` : "View on Modrinth"}
                                </a>
                                {plugin.wiki_url && (
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={plugin.wiki_url}
                                        className="font-extrabold text-black bg-orange-400 px-3 py-2 rounded-xl flex items-center justify-center mt-2"
                                    >
                                        Read Documentation
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
    </div>
}